@tailwind base;
@tailwind components;
@tailwind utilities;
 @import "https://fonts.googleapis.com/css?family=Raleway";

::-webkit-scrollbar {
    width: 0px;
    height: 20px;
}

::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: white;
}

::-webkit-scrollbar-thumb {
    background: #2f3842;
    border-radius: 100vh;
    border: 3px solid #2f3842;
}

body {
  font-family: Raleway;
  background-color: #2f3842;
  max-width: 100svw;
  overflow-x: hidden;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

/* Apply the animation */
.bounce-animation {
    animation: bounce 2s infinite ease-in-out;
}
